import { get } from '@vueuse/core';
export interface UserAuthOry {
  id: string
  traits: {
    email: string
    name: string
    entity?: string
  }
}
export default defineNuxtPlugin(() => {
  const userAuth = useOrySession()
  const identity: UserAuthOry = get(userAuth)?.identity || {
    id: 'development',
    traits: {
        email: 'development',
        name: 'development'
    }
  }
  if (process.client) {
    useBugsnag().setUser(identity.id, identity?.traits?.email, identity?.traits?.name)
    useBugsnag().addMetadata('company', { name: identity?.traits?.entity ?? 'dev' })
  }

});
